
import { useIdMixin } from '@teamnovu/utils'

export default {
  mixins: [useIdMixin({ baseProp: 'name' })],

  props: {
    name: {
      type: String,
      required: true,
    },

    value: {
      type: null,
      default: null,
    },

    rules: {
      type: [String, Array, Object],
      default: '',
    },

    mode: {
      type: [String, Function],
      default: () => (context) => {
        return { on: ['input'] }
      },
    },

    label: {
      type: String,
      default: null,
    },

    hint: {
      type: String,
      default: null,
    },

    placeholder: {
      type: String,
      default: null,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    actionWidth: {
      type: String,
      default: '0rem',
    },

    vid: {
      type: String,
      default: null,
    },

    appendIcon: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      resizeObserver: null,

      inputBounds: {
        left: 0,
        right: 0,
      },

      tooltipAnchorPoint: {
        x: 0,
        y: 0,
      },
    }
  },

  computed: {
    inputProps() {
      return {
        id: this.id,
        name: this.name,
        disabled: this.disabled,
        class: [
          'peer',
          {
            'placeholder-transparent': !this.placeholder,
          },
        ],

        placeholder: this.placeholder || this.label,

        paddingRight: this.calcInputPaddingRem([
          this.$refs.provider?.flags.validated &&
            this.$refs.provider?.flags.invalid,
          this.$scopedSlots.tooltip,
        ]),

        ...this.$attrs,
      }
    },
  },

  beforeMount() {
    this.resizeObserver = new ResizeObserver(this.onResize)
    window.addEventListener('resize', this.onResize)
  },

  mounted() {
    this.onResize()
    this.resizeObserver?.observe(this.$el)
  },

  destroyed() {
    this.resizeObserver?.disconnect()
    window.removeEventListener('resize', this.onResize)
  },

  methods: {
    calcInputPaddingRem(actions) {
      const x = actions.filter((a) => a).length

      return -0.1875 * Math.pow(x, 2) + 3.1875 * x + 1 + 'rem'
    },

    onResize(event) {
      const { left, right } = this.$el.getBoundingClientRect()

      this.inputBounds = {
        left: left + window.scrollX,
        right: right + window.scrollX,
      }

      if (this.$refs.popoverButton) {
        const { x, y, width, height } =
          this.$refs.popoverButton?.$el.getBoundingClientRect()

        this.tooltipAnchorPoint = {
          x: x + width / 2 + window.scrollX,
          y: y + height / 2 + window.scrollY,
        }
      }
    },
  },
}
