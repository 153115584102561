
export default {
  props: {
    value: {
      type: [String, Number],
      default: null,
    },

    type: {
      type: String,
      default: 'text',
    },
  },

  data() {
    return {
      internalValue: '',
    }
  },

  watch: {
    internalValue(newVal) {
      this.$emit('input', newVal)
    },

    value: {
      immediate: true,
      handler(newVal) {
        this.internalValue = newVal
      },
    },
  },
}
