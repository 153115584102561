
export default {
  props: {
    variant: {
      type: String,
      default: 'primary', // primary, secondary, outline
    },

    level: {
      type: String,
      default: '400',
    },
  },

  computed: {
    classObject() {
      return {
        'py-1 px-2 lg:py-2 text-sm': this.level === '50',
        'py-1.5 px-4': ['100', '200', '300'].includes(this.level),
        'py-2 px-3': this.level === '400',
        'p-2': this.level === 'round',
        'bg-brand text-white after:z-[-1] after:bg-brand-hover hover:bg-brand-hover disabled:cursor-not-allowed disabled:bg-brand-disabled':
          this.variant === 'primary',

        'text-brand hover:text-brand-hover disabled:cursor-not-allowed disabled:text-brand-disabled':
          this.variant === 'link',

        'border-2 border-brand bg-white text-brand hover:border-brand-hover hover:text-brand-hover disabled:cursor-not-allowed disabled:text-brand-disabled disabled:border-brand-disabled':
          this.variant === 'outline',
      }
    },
  },
}
