
import { useDispatchStoreActionMixin } from '@teamnovu/utils'

export default {
  mixins: [
    useDispatchStoreActionMixin({
      action: 'user/login',
    }),
  ],

  layout: 'guest',

  auth: 'guest',

  nuxtI18n: {
    paths: {
      de_CH: '/anmelden',
    },
  },

  data() {
    return {
      form: {
        email: '',
        password: '',
      },
    }
  },

  head() {
    return {
      title: this.$t('login.title'),
    }
  },

  methods: {
    async login() {
      try {
        await this.dispatchStoreAction(this.form)
      } catch {}
    },
  },
}
